import React from 'react';
import { Link } from 'gatsby-plugin-intl';
import { v4 as uuidv4 } from 'uuid';

function pressContent(props) {
  const getPostList = () => {
    const postList = [];
    props.data.allMarkdownRemark.edges.forEach((postEdge) => {
      postList.push({
        path: postEdge.node.fields.slug,
        cover: postEdge.node.frontmatter.cover,
        html: postEdge.node.html,
        title: postEdge.node.frontmatter.title,
        date: postEdge.node.frontmatter.date,
        excerpt: postEdge.node.excerpt,
        timeToRead: postEdge.node.timeToRead,
      });
    });
    return postList;
  };
  const postList = getPostList();
  return (
    <>
      <div className="site-wrap">
        <div className="site-container">
          <div className="site-content site-content-aside site-content-posts">
            <div className="articles">
              {postList.map((post) => (
                <div key={uuidv4()}>
                  <article>
                    <time dateTime={post.date}>{post.date}</time>
                    <h2>
                      <Link to={`/press-release/${post.path}`}>
                        {' '}
                        {post.title}{' '}
                      </Link>
                    </h2>
                    <p>{post.excerpt}</p>
                  </article>
                  <hr />
                </div>
              ))}
            </div>
            <aside className="press-list">
              <ol className="app-steps app-steps-b">
                <li className="title">
                  <div className="slogan">Previous</div>
                  <h2>Press Releases</h2>
                </li>

                {postList.map((post) => (
                  <li key={uuidv4()}>
                    <time dateTime={post.date}>{post.date}</time>
                    <Link to={`/press-release${post.path}`}>{post.title}</Link>
                  </li>
                ))}
              </ol>
            </aside>
          </div>
        </div>
      </div>
    </>
  );
}

export default pressContent;
