import React from 'react';
import { useIntl, Link } from 'gatsby-plugin-intl';
import { graphql } from 'gatsby';
import { v4 as uuidv4 } from 'uuid';

import SEO from '../components/SEO';
import Layout from '../layout';
import Footer from '../components/Footer/index';
import Heading from '../components/PressRelease/pageHeader';
import PressContent from '../components/PressRelease/pressContent';

const pressReleaseList = (props) => {
  const { data } = props;
  const intl = useIntl();
  const renderPaging = () => {
    const { currentPageNum, pageCount = 1 } = props.pageContext;
    const prevPage =
      currentPageNum - 1 === 1
        ? '/press-release/'
        : `/press-release/${currentPageNum - 1}/`;
    const nextPage = `/press-release/${currentPageNum + 1}/`;
    const isFirstPage = currentPageNum === 1;
    const isLastPage = currentPageNum === pageCount;

    if (pageCount === 1) {
      return null;
    }

    return (
      <nav className="paging-container site-content-aside">
        <ol className="articles pagination pagination-inline">
          {!isFirstPage && (
            <li>
              <Link to={prevPage}>Previous</Link>
            </li>
          )}
          {[...Array(pageCount)].map((_val, index) => {
            const pageNum = index + 1;
            return (
              <li
                className={currentPageNum === pageNum ? 'active' : ''}
                key={uuidv4()}
              >
                {currentPageNum !== pageNum ? (
                  <Link
                    to={
                      pageNum === 1
                        ? '/press-release/'
                        : `/press-release/${pageNum}/`
                    }
                  >
                    {' '}
                    {pageNum}{' '}
                  </Link>
                ) : (
                  <span>{pageNum}</span>
                )}
              </li>
            );
          })}
          {!isLastPage && (
            <li>
              <Link to={nextPage}>Next</Link>
            </li>
          )}
        </ol>
      </nav>
    );
  };
  return (
    <Layout>
      <Heading />
      <SEO
        meta={{
          title: `${intl.formatMessage({
            id: 'pressRelease.press',
          })} ${intl.formatMessage({
            id: 'pressRelease.release',
          })} | ${intl.formatMessage({ id: 'title' })}`,
          desc:
            'DanfeBooks’s press release distribution, news, events and features announcements',
          slug: `/press-release`,
        }}
      />
      <PressContent data={data} />
      <Footer>{renderPaging()}</Footer>
    </Layout>
  );
};

export default pressReleaseList;

/* eslint no-undef: "off" */
export const pressReleaseListQuery = graphql`
  query pressReleaseListQuery($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/press-release/.*\\\\.md$/" } }
      sort: { fields: [fields___date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          fields {
            slug
          }
          excerpt
          timeToRead
          html
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
          }
        }
      }
    }
  }
`;
