import React from 'react';
import { useIntl } from 'gatsby-plugin-intl';

function PageHeading({ noh = false }) {
  const intl = useIntl();
  return (
    <>
      <div className="masthead masthead-blog">
        <div className="masthead-contents">
          <div className="masthead-text">
            {noh ? (
              <div className="h1 small">
                {intl.formatMessage({ id: 'pressRelease.press' })}{' '}
                <strong>
                  {intl.formatMessage({ id: 'pressRelease.release' })}
                </strong>
              </div>
            ) : (
              <h1 className="small">
                {intl.formatMessage({ id: 'pressRelease.press' })}{' '}
                <strong>
                  {intl.formatMessage({ id: 'pressRelease.release' })}
                </strong>
              </h1>
            )}
            <p className="sub-lead">
              {intl.formatMessage({ id: 'pressRelease.subHeading' })}
            </p>
          </div>
        </div>
      </div>
      <div className="masthead-placeholder" />
    </>
  );
}

export default PageHeading;
